@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fb497e;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8b1832;
  }